import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Scrollbar = React.lazy(() => import("smooth-scrollbar"));
const Main = React.lazy(() => import("../views/home/Main"));
const Category = React.lazy(() => import("../views/Category"));
const ProjectDetails = React.lazy(() => import("../views/project/Details"));

const About = React.lazy(() => import("../views/About"));
const Contact = React.lazy(() => import("../views/contact/Contact"));
const Gallery = React.lazy(() => import("../views/Gallery"));
const Error = React.lazy(() => import("../views/Error"));

const Router = () => {
  const location = useLocation();
  const scrollbar: React.MutableRefObject<null | Scrollbar> = useSelector(
    (state) => state.scrollbar
  );

  const mountOnEnter = (e) => {
    scrollbar.current?.scrollTo(0, 0, 0);
    window.scrollTo({ left: 0, top: 0 });
  };

  return (
    <TransitionGroup id="dsn-content">
      <CSSTransition
        key={location.pathname}
        timeout={500}
        classNames="fade"
        onEnter={mountOnEnter}
      >
        <div className={`dsn-transition-page`}>
          <Suspense fallback={<div className="background-main h-100-v" />}>
            <Routes location={location} key={location.pathname}>
              <Route index path="/" element={<Main />} />
              <Route index path="/home" element={<Navigate to="/" />} />
              <Route exact path="/category/:cId" element={<Category />} />
              <Route
                exact
                path="/details/:cId/:slug"
                element={<ProjectDetails />}
              />
              <Route exact path="/gallery" element={<Gallery />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/contact" element={<Contact />} />
              {/* <Route exact path="/blog-details" element={<BlogDetails />} /> */}
              <Route exact path="*" element={<Error />} />
            </Routes>
          </Suspense>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default React.memo(Router);
