import React from "react";
import Navbar, { Nav } from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import SocialMedia from "../../social-media/SocialMedia";
import { useNavigate } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Services",
    dropdownMenu: [
      {
        name: "Character Creation",
        to: "/category/character-creation",
      },
      {
        name: "Hard Surface",
        to: "/category/hard-surface",
      },
      {
        name: "Product Rendering",
        to: "/category/product-rendering",
      },
      {
        name: "Architecture Rendering",
        to: "/category/architecture-rendering",
      },
      {
        name: "Assets",
        to: "/category/assets",
      },
    ],
  },
  //   {
  //     name: "Portfolio",
  //     dropdownMenu: [
  //       {
  //         name: "Sliders",
  //         dropdownMenu: [
  //           { name: "Full Screen", to: "/slider" },
  //           { name: "Padding Screen", to: "/slider-2" },
  //           { name: "Half Screen", to: "/slider-3" },
  //         ],
  //       },
  //       {
  //         name: "Grids",
  //         dropdownMenu: [
  //           { name: "Grid 2 Columns", to: "/portfolio" },
  //           { name: "Grid 3 Columns", to: "/portfolio-2" },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     name: "Works",
  //     dropdownMenu: [
  //       {
  //         name: "Works 01 - 05",
  //         dropdownMenu: [
  //           { name: "Time Tag Watch", to: "/portfolio/time-tag-watch" },
  //           { name: "Under Armour", to: "/portfolio/under-armour" },
  //           { name: "Re Styling", to: "/portfolio/re-styling" },
  //           { name: "Toast 2019 Reel", to: "/portfolio/toast-2019-reel" },
  //           { name: "What If Gold", to: "/portfolio/what-if-gold" },
  //         ],
  //       },
  //       {
  //         name: "Works 05 - 09",
  //         dropdownMenu: [
  //           { name: "Nile - Kabutha", to: "/portfolio/nile-kabutha" },
  //           { name: "Sleep Walker", to: "/portfolio/sleep-walker" },
  //           { name: "Novara Conic", to: "/portfolio/novara-conic" },
  //           { name: "Bastian Bux", to: "/portfolio/bastian-bux" },
  //         ],
  //       },
  //     ],
  //   },
  { name: "Gallery", to: "/gallery" },
  { name: "About", to: "/about" },
  { name: "Contact", to: "/contact" },
  // { name: "Careers", to: "/careers" },
];

const PixodoxMenu = ({ hamburger }) => {
  const navigate = useNavigate();
  let $key = 0;
  const getSubMenu = (items) => {
    $key++;
    if (items.dropdownMenu) {
      return (
        <Nav.Dropdown name={items.name} key={$key}>
          {items.dropdownMenu.map((item) => getSubMenu(item))}
        </Nav.Dropdown>
      );
    }
    return (
      <Nav.Link to={items.to} key={$key}>
        {items.name}
      </Nav.Link>
    );
  };

  return (
    <Navbar hamburger={hamburger}>
      <Navbar.Brand onClick={() => navigate("/home")}>
        <Brand width="240px" height="auto" logo={true} logoName={false} />
      </Navbar.Brand>

      <Navbar.Collapse cover="Menu">
        <Nav>
          {/* {window.innerWidth > 991 ? null : (
            <SocialMedia className="header-social-media" />
          )} */}
          {menuContent.map((item) => getSubMenu(item))}
        </Nav>
      </Navbar.Collapse>

      {/* {window.innerWidth > 991 && ( */}
      <SocialMedia className="header-social-media" />
      {/* )} */}
    </Navbar>
  );
};

PixodoxMenu.defaultProps = {
  hamburger: false,
};

export default PixodoxMenu;
