import React, { useRef } from "react";

import MainScrollBar from "./MainScrollBar";
import type { SmoothScrollbarProps } from "./SmoothScrollbar";
import PixodoxMenu from "../components/header/menu/PixodoxMenu";
import LoadingPage from "../hooks/LoadingPage";
// import CustomCursor from "./CustomCursor";
import { checkMobile, dsnCN } from "../hooks/helper";
// import ButtonOptionsTheme from "./ButtonOptionsTheme";

interface LayoutProps extends SmoothScrollbarProps {
  tag?: React.ElementType;
  activeScrollbar?: boolean;
  colorVersion: "dark" | "light";
}

function Layout({
  className,
  children,
  tag: Tag = "div",
  activeScrollbar,
  handleClickScroll,
  colorVersion,
  ...restProps
}: LayoutProps) {
  const layoutRef = useRef();

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <Tag
      id="main_layout"
      className={dsnCN(`v-${colorVersion}`, className)}
      ref={layoutRef}
      onContextMenu={handleContextMenu}
    >
      <PixodoxMenu hamburger />
      <LoadingPage />
      {activeScrollbar && !checkMobile() ? (
        <MainScrollBar {...restProps}>{children}</MainScrollBar>
      ) : (
        children
      )}
      {/* <CustomCursor
        duration={0.5}
        durationChangeSize={0.3}
        size={30}
        scale={70}
        ease="power2.out"
      /> */}
      {/* <ButtonOptionsTheme layoutRef={layoutRef} handleClickScroll={handleClickScroll}/> */}
    </Tag>
  );
}

Layout.defaultProps = {
  colorVersion: "dark",
};

export default Layout;
